import { container } from "assets/jss/material-kit-react.jsx";

import image from "assets/img/sign.jpg";

const Contactformstyle = {
  section: {
    minHeight: "110vh",
    maxHeight: "1600px",
    overflow: "hidden",
    padding: "70px 0",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    backgroundImage: "url(" + image + ")"
  },
  container,
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px"
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: "#495057"
  },
  inputIconsColorTwo: {
    color: "#fff"
  },
  marginform:{
    marginTop: "2rem",
  },
  buttonbg: {
    backgroundColor: "#00ADEE",
    borderRadius: "0px",
    textTransform: "none",
    "&:before": {
      position: "absolute",
      border: "4px solid #fff",
      borderRadius: "50%",
	    width: "18px",
	    height: "18px",
	    zIndex: "300",
      marginRight: "8rem",
      transitionTimingFunction: "ease-in-out",
      transition: "0.3s",
      content: "''",
    },
    "&:hover": {
      backgroundColor: "#1089b7",
      "&:before": {
        position: "absolute",
        border: "6px solid #fff",
        borderRadius: "50%",
        width: "18px",
        height: "18px",
        zIndex: "300",
        marginRight: "8rem",
        transitionTimingFunction: "ease-in-out",
        transition: "0.3s",
        content: "''",
      },
    },
  },
};

export default Contactformstyle;