import { container } from "assets/jss/material-kit-react.jsx";

const contactStyle = {
    container,
    brand: {
      color: "#FFFFFF",
      textAlign: "left",
      padding: "2rem 2rem",
      marginRight: "60%",
      marginLeft: "1%",
      backdropFilter: "blur(1px)",
      backgroundColor: "rgba(2, 88, 154, 0.7)",
      paddingBottom: "3.5rem",
      "@media (max-width: 1000px)": {
        marginRight: "30%",
      },
      "@media (max-width: 500px)": {
        marginRight: "20%",
        marginTop: "-200px"
      }
    },
    brandfooter: {
      color: "#FFFFFF",
      textAlign: "left",
      padding: "2rem 2rem",
      marginRight: "50%",
      marginLeft: "1%",
      backdropFilter: "blur(1px)",
      backgroundColor: "rgba(22, 71, 119, 0.7)",
      paddingBottom: "3.5rem",
      "@media (max-width: 1000px)": {
        marginRight: "10%",
      },
      "@media (max-width: 500px)": {
        marginRight: "5%",
      }
    },
    intro: {
      fontSize: "3rem",
      fontWeight: "500",
      textAlign: "left",
      marginTop: "0.5rem",
      letterSpacing: "0.02em",
      "@media (max-width: 800px)": {
        fontSize: "2rem",
      },
    },
    title: {
      fontSize: "3rem",
      fontWeight: "500",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      "@media (max-width: 800px)": {
        fontSize: "2rem",
      }
    },
    aftertitle: {
      marginTop: "-2px",
      fontWeight: "500",
      fontSize: "1rem",
      textTransform: "uppercase",
      marginBottom: "2.5rem",
      "@media (max-width: 800px)": {
        fontSize: "1.2rem",
      }
    },
    subtitle: {
      fontSize: "1.25rem",
      fontWeight: "300",
      textAlign: "left",
      marginBottom: "2.5rem",
      paddingTop: "3.5rem",
      "@media (max-width: 800px)": {
        fontSize: "0.9rem",
      },
    },
    main: {
      background: "#fff",
      position: "relative",
      zIndex: "3"
    },
    mainRaised: {
      },
    mainlinks: {
        background: "#E1F4FD",
        position: "relative",
        zIndex: "3",
        paddingTop: "2rem",
      },
      mainalt: {
          background: "#E1F4FD",
          position: "relative",
          zIndex: "3",
        },
    mainRaisedlinks: {
        },
  
    mainwrap: {
      margin: "auto",
      maxWidth: "1000px",
    },
    mainwrapfoot: {
      margin: "auto",
    },
  
    mainwraplinks: {
      margin: "auto",
      maxWidth: "1000px",
    },
  
    link: {
      textDecoration: "none"
    },
    textCenter: {
      textAlign: "center"
    },
    heading: {
      marginTop: "-2px",
      fontSize: "3.4rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "40px",
      "@media (max-width: 1300px)": {
        marginTop: "-2px",
        fontSize: "2rem",
        fontWeight: "800",
        display: "inline-block",
        position: "relative",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#0b292c",
        marginBottom: "4rem",
        marginLeft: "20px",
      },
      "@media (max-width: 600px)": {
        marginTop: "-2px",
        fontSize: "2rem",
        fontWeight: "800",
        display: "inline-block",
        position: "relative",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#0b292c",
        marginBottom: "4rem",
        marginLeft: "auto",
        width: "100%",
        textAlign: "center",
      }
    },
    preheading: {
      fontSize: "1.75rem",
      color: "#164777",
      opacity: "0.95",
      marginTop: "5rem",
      marginBottom: "2.5rem",
      fontWeight: "400",
      "@media (max-width: 1300px)": {
        fontSize: "2rem",
        color: "#164777",
        opacity: "0.95",
        marginTop: "4rem",
        marginBottom: "2rem",
        fontWeight: "400",
        textAlign: "left",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        lineHeight: "1.5",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.5rem",
        color: "#164777",
        opacity: "0.95",
        marginTop: "3rem",
        marginBottom: "2rem",
        marginLeft: "auto",
        width: "100%",
        textAlign: "center",
        fontWeight: "400",
        lineHeight: "1.25",
      }
    },
    preheadingteam: {
      fontSize: "1.75rem",
      color: "#164777",
      opacity: "0.95",
      marginTop: "5rem",
      marginLeft: "1rem",
      marginBottom: "2.5rem",
      fontWeight: "400",
      "@media (max-width: 958px)": {
        fontSize: "2rem",
        color: "#164777",
        opacity: "0.95",
        marginTop: "1rem",
        marginBottom: "2rem",
        fontWeight: "400",
        textAlign: "left",
        marginLeft: "2.5rem",
        marginRight: "1.5rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.5rem",
        color: "#164777",
        opacity: "0.95",
        marginTop: "1rem",
        marginBottom: "2rem",
        marginLeft: "1.5rem",
        width: "100%",
        textAlign: "left",
        fontWeight: "400",
      }
    },
    parawrap: {
      position: "relative",
      "@media (max-width: 1300px)": {
        position: "relative",
        maxWidth: "100%",
        marginRight: "1.5rem",
      },
      "@media (max-width: 600px)": {
        maxWidth: "100%",
        position: "relative",
        marginLeft: "10px",
        marginRight: "10px",
      }
    },
    paratext: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      marginBottom: "4.5rem",
      "@media (max-width: 1300px)": {
        fontSize: "0.9rem",
        marginBottom: "3.2rem",
        textAlign: "left",
        lineHeight: "1.5",
      },
      "@media (max-width: 600px)": {
        fontSize: "0.8rem",
        marginBottom: "3.2rem",
        textAlign: "center",
        lineHeight: "1.5",
      }
    },
    paratextteam: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      marginBottom: "4.5rem",
      marginLeft: "1rem",
      "@media (max-width: 1300px)": {
        fontSize: "0.9rem",
        marginBottom: "3.2rem",
        marginLeft: "2.5rem",
        textAlign: "left",
        lineHeight: "1.5",
      },
      "@media (max-width: 600px)": {
        fontSize: "0.8rem",
        marginBottom: "3.2rem",
        textAlign: "left",
        lineHeight: "1.5",
        marginRight: "1rem",
        marginLeft: "1.5rem",
      }
    },
    parawraplinks: {
      position: "relative",
      "@media (max-width: 1300px)": {
        position: "relative",
        maxWidth: "100%",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
      },
      "@media (max-width: 600px)": {
        maxWidth: "100%",
        position: "relative",
        marginLeft: "10px",
        marginRight: "10px",
      }
    },
    paratextlinks: {
      fontSize: "1.1rem",
      lineHeight: "1.5",
      marginBottom: "1.2rem",
      textAlign: "left",
      paddingLeft: "1rem",
      paddingTop: "2.5rem",
      paddingBottom: "2.5rem",
      "@media (max-width: 1300px)": {
        fontSize: "0.9rem",
        marginBottom: "1.2rem",
        textAlign: "left",
        lineHeight: "1.5",
        textAlign: "left",
        paddingLeft: "0",
      },
      "@media (max-width: 600px)": {
        fontSize: "0.8rem",
        marginBottom: "1.2rem",
        textAlign: "left",
        lineHeight: "1.5",
        paddingLeft: "1rem",
      }
    },
    homeimage: {
      maxWidth: "100%",
      maxHeight: "100%",
      overflow: "hidden",
    },
    colliery: {
      maxWidth: "70%",
      maxHeight: "70%",
      overflow: "hidden",
    },
    imagewrap: {
      marginTop: "2rem",
      marginBottom: "3rem",
      width: "100%",
      height: "auto",
      marginLeft: "1rem",
      marginRight: "1rem",
      "@media (max-width: 1300px)": {
        width: "90%",
      height: "auto",
      },
      "@media (max-width: 500px)": {
        width: "93%",
      height: "auto",
      }
    },
    imagewrapteam: {
      marginTop: "4rem",
      marginBottom: "3rem",
      width: "100%",
      height: "auto",
      marginLeft: "1rem",
      marginRight: "1rem",
      "@media (max-width: 1300px)": {
        width: "95%",
      height: "auto",
      },
      "@media (max-width: 500px)": {
        width: "92%",
      height: "auto",
      }
    },
    footerimagewrap: {
      marginTop: "2rem",
      marginBottom: "3rem",
      width: "100%",
      height: "auto",
      marginRight: "1rem",
      "@media (max-width: 1300px)": {
        width: "90%",
      height: "auto",
      },
      "@media (max-width: 500px)": {
        width: "93%",
      height: "auto",
      }
    },
    imagewrapfoot: {
      width: "100%",
      height: "auto",
      "@media (max-width: 1300px)": {
      height: "auto",
      },
      "@media (max-width: 500px)": {
      height: "auto",
      }
    },
    bgimg: {
      zIndex: "500",
      margin: "0",
      border: "0",
      display: "flex",
      overflow: "hidden",
      minHeight: "40vh",
      maxHeight: "70vh",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    headinginverse: {
      marginTop: "-2px",
      fontSize: "3.4rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "40%",
      "@media (max-width: 1300px)": {
        marginTop: "-2px",
        fontSize: "2rem",
        fontWeight: "800",
        display: "inline-block",
        position: "relative",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#0b292c",
        marginBottom: "4rem",
        marginLeft: "7%",
      },
      "@media (max-width: 600px)": {
        marginTop: "-2px",
        fontSize: "2rem",
        fontWeight: "800",
        display: "inline-block",
        position: "relative",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        color: "#0b292c",
        marginBottom: "4rem",
        marginLeft: "auto",
        width: "100%",
        textAlign: "center",
      }
    },
    preheadinginverse: {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "10%",
      marginBottom: "5px",
      marginLeft: "40.5%",
      "@media (max-width: 1300px)": {
        fontSize: "1.5rem",
        textTransform: "uppercase",
        color: "#0b292c",
        opacity: "0.9",
        marginTop: "120px",
        marginBottom: "5px",
        marginLeft: "7.5%",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.5rem",
        textTransform: "uppercase",
        color: "#0b292c",
        opacity: "0.9",
        marginTop: "150px",
        marginBottom: "5px",
        marginLeft: "auto",
        width: "100%",
        textAlign: "center",
      }
    },
    parawrapinverse: {
      position: "relative",
      marginLeft: "40%",
      marginRight: "40px",
      "@media (max-width: 1300px)": {
        position: "relative",
        marginLeft: "7%",
        marginRight: "40px",
      },
      "@media (max-width: 600px)": {
        maxWidth: "100%",
        position: "relative",
        marginLeft: "25px",
        marginRight: "25px",
      }
    },
    paratextinverse: {
      fontSize: "14px",
      marginBottom: "1.2rem",
      "@media (max-width: 1300px)": {
        fontSize: "12px",
        marginBottom: "1.2rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "12px",
        marginBottom: "1.2rem",
        textAlign: "center",
      }
    },
    paddingone: {
      paddingBottom: "10rem",
    },
    buttonwrap: {
      "@media (max-width: 600px)": {
        marginLeft: "20px",
        marginRight: "20px",
      }
    },
    ulist:{
        lineHeight: "2",
        "@media (max-width: 1300px)": {
          marginLeft: "2.5rem",
        },
        "@media (max-width: 600px)": {
  
        }
    }, 
    listwrap: {
      "@media (max-width: 600px)": {
          marginLeft: "1rem",
        }
    },
    locationtitle:{
        color: "#164777",
        marginTop: "3rem",
        fontSize: "1rem",
        marginLeft: "10px",
        marginRight: "10px",
    },
    locationdetails:{
        color: "#231F20",
        fontSize: "0.9rem",
        marginTop: "0.5rem",
        lineHeight: "1.5",
        marginLeft: "10px",
        marginRight: "10px",
    },
    contactdetails:{
        color: "#231F20",
        fontSize: "0.9rem",
        marginTop: "1.75rem",
        marginLeft: "10px",
        marginRight: "10px",
    },
    contactwrap:{
        marginTop: "4rem",
        marginLeft: "10px",
        marginRight: "10px",
      },
      paratextcontact:{
          fontSize: "0.9rem",
          textAlign: "center",
      },
      paratextcontactpost:{
        fontSize: "0.8rem",
        textAlign: "left",
        marginBottom: "2rem",
        marginLeft: "10px",
        marginRight: "10px",
    },
    inputIconsColor: {
        color: "#00ADEE",
        marginRight: "0.5rem",
        marginBottom: "-0.3rem",
      },
      contactlinks: {
        color: "#231F20",
      },
  };
  
  
  
  export default contactStyle;