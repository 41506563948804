import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonESG from "components/CustomButtons/ButtonESG.jsx";
import ButtonWWD from "components/CustomButtons/ButtonWWD.jsx";
import ButtonColliery from "components/CustomButtons/ButtonColliery.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import esgimg from "../../assets/img/ESGHeader.jpg";
import wwd from "../../assets/img/wwd.jpg";
import innerrender from "../../assets/img/InnerRender.jpg";
import Briscombe from "../../assets/img/GreggsBuilding.jpg";
import andrewimage from "../../assets/img/AThomson.jpg";
import image from "../../assets/img/footimg.png";
import colliery from "../../assets/img/colliery.png";
import ContactForm from "components/ContactForm/ContactForm.js"
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import PrivacyModal from "components/PrivacyModal/PrivacyModal.jsx";

import contactStyle from "assets/jss/material-kit-react/views/contactPage.jsx";

class ContactPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/ContactHeader.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  Contact us
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <div className={classes.locationtitle}>
                  In the United Kingdom
            </div> 
            <div className={classes.locationdetails}>
            Virtuous Circle Developments
            <br/>
            21 C Park Crescent
            <br/>
            Barry
            <br/>
            Wales
            <br/>
            United Kingdom
            </div>   
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <div className={classes.locationtitle}>
                  In the UAE
            </div> 
            <div className={classes.locationdetails}>
            Virtuous Circle Developments
            <br/>
            1st Floor Synergy Smarter Office Solutions
            <br/>
            Bay Square 3
            <br/>
            Business Bay
            <br/>
            Dubai, United Arab Emirates
            </div>   
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.contactdetails}>
          <a className={classes.contactlinks} href="mailto:contact@vcdevelopments.co.uk">
          <Email className={classes.inputIconsColor}/>
          contact@vcdevelopments.co.uk
          </a>
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.contactdetails}>
          <a className={classes.contactlinks} href="tel:+4401446678413">
          <Phone className={classes.inputIconsColor}/>
          +44 (0) 1446 678413
          </a>
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
                 
                <div className={classes.contactwrap}>
                <p className={classes.paratextcontact}>
                To find out more about any of our services or the locations we operate in please complete the form below to get in touch.
                <br/>
                
                </p>
              </div>
                
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
     
            <ContactForm/>
                 
                 
             </GridItem>

             <GridItem xs={12} sm={12} md={12}>
                 
                 <div className={classes.contactwrap}>
                 <p className={classes.paratextcontactpost}>
                 All of your information is kept private.

                 <br/>
                 We do not pass your information on to any third parties or affiliates.
                
                <br/>
                You can view our privacy policy here: <PrivacyModal />.
                 </p>
                 
               </div>
               
             </GridItem>
             
            </GridContainer>

            </div>  
          </div>


        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandfooter}>
                  <div className={classes.footerimagewrap}>
          <img alt="..." src={colliery} className={classes.colliery} />
          </div>
                <ButtonColliery/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(contactStyle)(ContactPage);