import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "gatsby";

import Close from "@material-ui/icons/Close";

// core components

import Button from "../../components/CustomButtons/Button.jsx";
import javascriptStyles from "../../assets/jss/material-kit-react/views/javascriptStyles.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class PrivacyModal extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false,
    };
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.termscontainer}>
        <Button
          color="transparent"
          block
          onClick={() => this.handleClickOpen("classicModal")}
        >
          Privacy Policy
        </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("classicModal")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose("classicModal")}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>Privacy Policy</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <p>
            We are committed to ensuring that your privacy is protected. Should we ask you to provide information by which you can be identified when using this website, or by other means you can be assured that it will only be used in accordance with this privacy statement and the EU General Data Protection Regulation. Unless we are negligent in exercising our responsibilities then we shall not be held liable for unauthorised access to information supplied by you. We may occasionally change this policy by updating this page. You should check this page from time to time to ensure that you are happy with any changes. 
            </p>

            <h5 className={classes.modalpara}>What information do we collect and how do we use it? </h5>

            <p>
            We do not collect any unique information about you – such as your name, email address, contact details, etc. – except when you specifically provide it. We do collect and hold data when you submit online sign-up forms on our website or contact us via email. In addition to holding your details for internal record keeping and customer communications, with your explicit permission we may share with you news, content and business information we think relevant to you, namely:
            </p>

            <p>
            Blog and website articles;
            <br/>
            Newsletters;
            <br/>
            Upcoming events we think would be of interest to you.
            <br/>
            Other product or service information relevant to our business relationship with you.

            </p>

   

            <p>
            By submitting personal details to us via online forms you are consenting to the use of those details for the purposes of identifying you as a unique user. Your details are securely stored in our email database. The information you provide will not be shared with any other entity or organisation, without your explicit consent (or if required to do so by law) – apart from our service providers who operate elements of our service and process data on our behalf. We will not use the information submitted through forms on the website for any other purpose if you have not indicated that you are happy for us to do so. We will not pass your contact details to any third party for any marketing communication without having first received your explicit consent. As required by the General Data Protection Regulation (GDPR) we follow strict security procedures in relation to the storage and disclosure of information which you have given to us, to prevent unauthorised access or use of such data. 

            </p>

            <p>
            You have the right to object to this processing, if you wish, by emailing with the subject title “Privacy Policy”: info@vcdevelopments.co.uk 
            <br/>
            Please bear in mind that if you object it may affect our ability to carry out the tasks above for your benefit. 
            </p>

            <h5 className={classes.modalpara}>What does legitimate interests mean?</h5>

            <p>
            Legitimate interests mean the interests of our company in conducting and managing our business to enable us to give you the best products and the most secure experience. For example, we have an interest in making sure our marketing is relevant to you, so we may process your information to send you marketing tailored to your interests. It can also apply to processing that is in your interests as well. For example, we process your information to ensure our websites and systems are secure. When we process your personal information for our legitimate interests, we make sure to consider and balance any potential impact on you (both positive and negative) and your rights under data protection laws. Our legitimate business interests do not override your interests and we will not use your personal data for activities where our interests are overridden by the impact on you (unless we have your permission or are required or permitted to by law). You have the right to opt out, if you wish, by emailing with the subject title “Privacy Policy”: info@vcdevelopments.co.uk 
            </p>
            
            <h5 className={classes.modalpara}>Updating or Deleting Your Information</h5>

            <p>
            Details of the data we hold on you will be provided to you on request. Any request of this nature should be directed to the e-mail address shown at the bottom of this notice. Any personal data we hold on you will be deleted or updated upon request and any inaccurate or misleading data held will be changed or deleted upon your request. You have the right to access any of the information held about you by emailing with the subject title “Privacy Policy”: info@vcdevelopments.co.uk You have the right to unsubscribe from our database by selecting unsubscribe option at the bottom of any of our communications or by contacting with the subject title “Privacy Policy” info@vcdevelopments.co.uk
            </p>


            <h5 className={classes.modalpara}>Links to other sites</h5>

            <p>
            Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question. 
            </p>

            <h5 className={classes.modalpara}>With whom do we share your information?</h5>

            <p>
            We will not share any personally identifiable information with any third party without your express consent. 
            </p>

            <h5 className={classes.modalpara}>Cookies</h5>

            <p>
            We do note and save information such as time visit, pages visited, length of visit and content viewed when you visit our website. This information is used to provide more relevant services to users. Cookies do not collect any unique information about you. A cookie is a small file which asks permission to be placed on your computers hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website. To find out more about cookies, visit www.allaboutcookies.org The information below explains the cookies we use and why.
            </p>

            <h5 className={classes.modalpara}>Contacting Us</h5>

            <p>
              Please do not hesitate to contact us regarding any matter relating
              to this Privacy Policy at info@vcdevelopments.co.uk
            </p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Link to="/contact">
              <Button
                color="transparent"
                simple
                onClick={() => this.handleClose("classicModal")}
              >
                Contact Us
              </Button>
            </Link>
            <Button
              onClick={() => this.handleClose("classicModal")}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(javascriptStyles)(PrivacyModal);
